import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import Select from '../js/components/Select';
import { ThemeProvider } from 'styled-components';
import { themeConWeb, themeEnterprise, themeGO } from '../js/themes';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "select"
    }}>{`Select`}</h1>
    <p><inlineCode parentName="p">{`import { Select } from '@foodsby/nutrient'`}</inlineCode></p>
    <p>{`The Select component is a prestyled `}<a parentName="p" {...{
        "href": "https://react-select.com/home"
      }}>{`react-select`}</a>{`.`}</p>
    <h2 {...{
      "id": "playground"
    }}>{`Playground`}</h2>
    <Playground __position={0} __code={'<ThemeProvider theme={themeConWeb}>\n  <div style={{ minHeight: \'200px\' }}>\n    <Select\n      menuIsOpen={true}\n      options={[\n        { value: \'chocolate\', label: \'Chocolate\' },\n        { value: \'strawberry\', label: \'Strawberry\' },\n        { value: \'vanilla\', label: \'Vanilla\' },\n      ]}\n    />\n  </div>\n</ThemeProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Select,
      ThemeProvider,
      themeConWeb,
      themeEnterprise,
      themeGO
    }} mdxType="Playground">
  <ThemeProvider theme={themeConWeb} mdxType="ThemeProvider">
  <div style={{
          minHeight: '200px'
        }}>
    <Select menuIsOpen={true} options={[{
            value: 'chocolate',
            label: 'Chocolate'
          }, {
            value: 'strawberry',
            label: 'Strawberry'
          }, {
            value: 'vanilla',
            label: 'Vanilla'
          }]} mdxType="Select" />
  </div>
  </ThemeProvider>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
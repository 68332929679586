import React from 'react'
import ReactSelect from 'react-select'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box } from './Box'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { space } from 'styled-system'

const StyledSelect = styled(ReactSelect)`
  & .nutrient__control {
    min-height: 40px;
    background-color: ${props => props.theme.color.brandPrimaryTint8};

    &.nutrient__control--is-focused {
      border: solid 1px ${props => props.theme.color.brandPrimary};
      box-shadow: ${props => props.theme.color.boxShadow};
    }
    &.nutrient__value-container {
      background: ${props => props.theme.color.brandPrimaryTint8};
      font-family: ${props => props.theme.fonts.primary};
      font-size: 18px;
    }
  }

  & .nutrient__placeholder {
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.color.greyShade3};
  }
  & .nutrient__value {
    & .nutrient__value-container {
      background-color: ${props => props.theme.color.brandPrimaryTint8};
    }
  }
  & .nutrient__dropdown {
    & .nutrient__dropdown-indicator {
      background-color: ${props => props.theme.color.brandPrimaryTint8};
    }
  }
  & .nutrient__indicators {
    & .nutrient__indicator-container {
      background-color: none;
    }
  }
  & .nutrient__clear {
    & .nutrient__clear-indicator {
      background-color: ${props => props.theme.color.brandPrimaryTint8};
      border-bottom: 2px solid ${props => props.theme.color.greyShade5};
    }
  }
  & .nutrient__menu {
    background: ${props => props.theme.color.white};
    margin: 0;
  }
  & .nutrient__indicator {
    color: ${props => props.theme.color.brandPrimary};
  }
  & .nutrient__option {
    font-family: ${props => props.theme.fonts.primary};
    font-size: 18px;

    &.nutrient__option--is-selected {
      color: ${props => props.theme.color.brandPrimary};
      background: ${props => props.theme.color.white};

      &:after {
        content: '✓';
        float: right;
        color: ${props => props.theme.color.brandPrimary};
      }
    }
    &.nutrient__option--is-focused {
      background-color: ${props => props.theme.color.brandPrimaryTint8};
    }
  }

  & span[class$='-a11yText'] {
    background-color: red;
    border: 0px none;
    clip: rect(1px, 1px, 1px, 1px);
    color: blue;
    height: 1px;
    overflow: hidden;
    padding: 0px;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    z-index: 9999;
  }
`
const StyledLabel = styled.label`
  color: ${props => props.theme.color.greyShade5};
  font-size: 12px;
  margin-bottom: 4px;
`
export const Select = ({
  isMulti = false,
  options = [],
  children,
  label,
  selectProps = {},
  ...rest
}) => (
  <Box {...pick(rest, [...Object.keys(space)])}>
    {label && <StyledLabel>{label}</StyledLabel>}
    <StyledSelect
      classNamePrefix="nutrient"
      isMulti={isMulti}
      options={options}
      selectProps={selectProps}
      {...omit(rest, [...Object.keys(space)])}
    >
      {children}
    </StyledSelect>
  </Box>
)

Select.propTypes = {
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node,
  label: PropTypes.node,
  selectProps: PropTypes.object
}
export default Select
